<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Transport System Sample Log</h4>
                        <form @submit.stop.prevent="oneditBicker()">
                            <div class="row" >
                                    <div class="col-md-4">
                                        <label >Transport System name</label>
                                        <multiselect v-model="biker_name" :select="selection(biker_name)" label="name" :options="bikerOption" :multiple="false" placeholder="Select Transport System"></multiselect>
                                    </div>
                                    <div class="col-md-4">
                                        <label >Coverage area</label>
                                        <input v-model="form.coverage_area"
                                                type="text"
                                                placeholder="Coverage area"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Month/year</label>
                                        <input v-model="ptData"
                                               disabled
                                               type="text"
                                               placeholder="Dropped up site"
                                               class="form-control"
                                        />
                                        <!--<date-picker  format="M/Y" v-model="ptData.year" placeholder="Month/year" :first-day-of-week="1" lang="en"></date-picker>-->
                                    </div>
                                    <div class="col-md-4">
                                        <label >Date of Drop</label>
                                        <date-picker  v-model="date_of_drop" placeholder="Date of Drop" :first-day-of-week="1" lang="en"></date-picker>
                                    </div><div class="col-md-4">
                                        <label >Time of drop</label>
                                        <date-picker 
                                                v-model="form.time_of_drop"
                                                format="hh:mm:ss a"
                                                value-type="format"
                                                type="time"
                                                placeholder="hh:mm:ss a"
                                        ></date-picker>
                                    </div>
                                    <div class="col-md-4">
                                        <label >Description of Item</label>
                                        <input v-model="form.description_of_items"
                                        
                                                type="text"
                                                placeholder="Description of Item"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Dropped up site</label>
                                        <input v-model="form.site.site_name" 
                                                type="text"
                                                placeholder="Dropped up site"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Recipient Initials and signature</label>
                                        <multiselect  v-model="form.recipients_initials_and_signature"  :options="options3" :multiple="false" placeholder="Recipient Initials and signature"></multiselect>
                                    </div>
                                    <div class="col-md-4">
                                        <label >Date of pick up</label>
                                        <date-picker  v-model="date_of_pickup" placeholder="Date of pick up " :first-day-of-week="1" lang="en"></date-picker>
                                    </div><div class="col-md-4">
                                    <label >Time of pick up</label>
                                    <date-picker 
                                            v-model="form.time_of_pickup"
                                            format="hh:mm:ss a"
                                            value-type="format"
                                            type="time"
                                            placeholder="hh:mm:ss a"
                                    ></date-picker>
                                    </div>
                                    <div class="col-md-4">
                                        <label >Pick up site</label>
                                        <input v-model="form.pick_up_site" 
                                                type="text"
                                                placeholder="Pick up site"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Total number of items picked up</label>
                                        <input v-model="form.total_number_of_items_pickedup" 
                                                type="number"
                                                placeholder="Total number of items picked up"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Distance travelled(Km)</label>
                                        <input v-model="form.distance_travelled" 
                                                type="number"
                                                placeholder="Distance travelled(Km)"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Shipper/sender initials and signature </label>
                                        <multiselect  v-model="form.shipper_and_sender_initials_and_signature"  :options="options3" :multiple="false" placeholder="Shipper/sender initials and signature"></multiselect>
                                    </div>
                                </div>
                                <div style="display: flex; justify-content: flex-end">
                                    <button class="btn btn-primary mt-3" type="submit">Save</button>
                                </div>
                            
                        </form>
                        </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Fill results form</h4>
                        <!-- <div class="row">
                            <div class="col-lg-6">
                                <label >Select Site Type</label>
                                <multiselect v-model="value2" :options="options2" @select="selectSite" :multiple="false" placeholder="Select site type"></multiselect>
                            </div>
                        </div> -->
                        <div class="row" v-if="form.site.site_category === 'VL'">
                                <div class="col-md-4">
                                    <label >PT Panel ID</label>
                                    <input v-model="form.pt_panel_id"
                                        disabled
                                            type="text"
                                            
                                            placeholder="PT Panel ID"
                                            class="form-control"
                                    />
                                </div>
                                <!-- <div class="col-md-4">
                                    <label >Country</label>
                                    <input v-model="form.site.country"
                                        disabled
                                            value="country"
                                            type="text"
                                            placeholder="Country"
                                            class="form-control"
                                    />
                                </div> -->
                                <div class="col-md-4">
                                    <label >Due date</label>
                                    <input v-model="form.due_date"
                                            value="dueDate"
                                            type="date"
                                            
                                            placeholder="Due date"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Site PTID</label>
                                    <input v-model="form.site.ptid"
                                            type="text"
                                            
                                            placeholder="Site PTID"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Site name</label>
                                    <input v-model="form.site.site_name"
                                        disabled 
                                            type="text"
                                            placeholder="Site name"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Site email</label>
                                    <input v-model="form.site.email"
                                        disabled
                                            type="email"
                                            
                                            placeholder="Site email"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Contact telephone</label>
                                    <input v-model="form.site.telephone"
                                        disabled
                                            type="text"
                                            
                                            placeholder="Contact telephone"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Date PT received</label>
                                    <input v-model="form.date_pt_recieved"
                                            type="date"
                                            
                                            placeholder="Date PT received"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Date PT result reported</label>
                                    <input v-model="form.date_pt_result_reported"
                                            type="date"
                                            
                                            placeholder="Date PT result reported"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Instrument</label>
                                    <!--<input v-model="form.instrument"
                                            type="text"
                                            
                                            placeholder="Instrument"
                                            class="form-control"
                                    />-->
                                    <input v-model="form.instrument"
                                           list="list-timezone"
                                           type="text"
                                           autocomplete="on"
                                           placeholder="Instrument"
                                           class="form-control"
                                           id="input-datalist"
                                    />
                                    <datalist id="list-timezone">
                                        <option v-for="item in instruments_options" :key="item">{{item}}</option>
                                    </datalist>
                                </div>
                                <div class="col-md-4">
                                    <label >Cartridge Lot number</label>
                                    <input v-model="form.catridge_lot_number"
                                            type="number"
                                            autocomplete="on"
                                            list="list-catridges"
                                            placeholder="Cartridge Lot number"
                                            class="form-control"
                                           id="input-datalist"
                                    />
                                    <!--<input v-model="form.instrument"
                                           list="list-timezone"
                                           type="text"
                                           autocomplete="on"
                                           placeholder="Instrument"
                                           class="form-control"
                                           id="input-datalist"
                                    />-->
                                    <datalist id="list-catridges">
                                        <option v-for="item in catridges_options" :key="item">{{item}}</option>
                                    </datalist>
                                </div>
                                <div class="col-md-4">
                                    <label >Cartridge expiry date</label>
                                    <input v-model="form.catridge_expiry_date"
                                            type="date"
                                            
                                            placeholder="Cartridge expiry date"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Sample ID</label>
                                    <input v-model="form.sample_id"
                                            type="text"
                                            
                                            placeholder="Sample ID"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Date tested</label>
                                    <input v-model="form.date_tested"
                                            type="date"
                                            
                                            placeholder="Cartridge expiry date"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Time rehydrated</label>
                                    <date-picker
                                            v-model="form.time_rehydrated"
                                            format="hh:mm:ss a"
                                            
                                            value-type="format"
                                            type="time"
                                            placeholder="hh:mm:ss a"
                                    ></date-picker>
                                </div>
                                <div class="col-md-4">
                                    <label >Time tested</label>
                                    <date-picker
                                            v-model="form.time_tested"
                                            format="hh:mm:ss a"
                                            value-type="format"
                                            
                                            type="time"
                                            placeholder="hh:mm:ss a"
                                    ></date-picker>
                                </div>
                                <div class="col-md-4">
                                    <label >Results 1</label>
                                    <input v-model="form.results_sample1"
                                            
                                            type="number"
                                            placeholder="Results"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Results 2</label>
                                    <input v-model="form.results_sample2" 
                                            type="number"
                                            placeholder="Results"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Results 3</label>
                                    <input v-model="form.results_sample3" 
                                            type="number"
                                            placeholder="Results"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Results 4</label>
                                    <input v-model="form.results_sample4" 
                                            type="number"
                                            placeholder="Results"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Results 5</label>
                                    <input v-model="form.results_sample5" 
                                            type="number"
                                            placeholder="Results"
                                            class="form-control"
                                    />
                                </div>
                                <!--<div class="col-md-4">
                                    <label >Error Code</label>
                                    <multiselect  v-model="form.error_code" :options="options4"  :multiple="false"   >
                                        </multiselect>
                                </div>-->
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Operator Name</label>
                                    <input type="text" placeholder="Operator Name" v-model="form.operator_name" class="form-control"/>
                                </div>
                            </div>
                                <div class="col-md-4">
                                    <label >Name and signature of tester</label>
                                    <multiselect  :options="options3" v-model="form.name_and_signature_of_tester" placeholder="Name and signature of tester"></multiselect>
                                    <!-- <input v-model="form.name_and_signature_of_tester"
                                            type="text"
                                            placeholder="Name and signature of tester"
                                            class="form-control"
                                    /> -->
                                </div>
                                <div class="col-md-4">
                                    <label >Name and signature of reviewer</label>
                                    <multiselect  :options="options3" v-model="form.name_and_signature_of_reviewer" placeholder="Name and signature of reviewer"></multiselect>
                                    <!-- <input v-model="form.name_and_signature_of_reviewer"
                                            type="text"
                                            placeholder="Name and signature of reviewer"
                                            class="form-control"
                                    /> -->
                                </div>
                                <div class="col-md-4">
                                    <label >Comment </label>
                                    <input v-model="form.comment"
                                            type="text"
                                            placeholder="Comment"
                                            
                                            class="form-control"
                                    />
                                </div>
                            <div class="col-md-4 mt-4" style="display: flex; align-items: center">
                                <input type="file" accept="image/jpeg" @change="uploadImage"/>
                                <img v-if="form.filename" :src="baseurl+'/uploads/'+form.filename" width="100"/>
                            </div>
                            <div v-if="form.filename || previewImage" class="col-md-4 mt-4" style="display: flex; align-items: center">
                                <button class="btn btn-danger" @click.prevent="removeImage()">Remove Image</button>
                            </div>
                        </div>
                        <div class="row" v-else>
                                <div class="col-md-4">
                                    <label >Site PT ID</label>
                                    <input v-model="form.site.ptid"
                                        disabled
                                            type="text"
                                            
                                            placeholder="Site PT ID"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Site name</label>
                                    <input v-model="form.site.site_name"
                                        disabled
                                            type="text"
                                            
                                            placeholder="Site name"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Site email</label>
                                    <input v-model="form.site.email"
                                        disabled
                                            type="email"
                                            
                                            placeholder="Site email"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Site contact</label>
                                    <input v-model="form.site.telephone"
                                        disabled
                                            type="number"
                                            
                                            placeholder="Contact telephone"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Laboratory Director name</label>
                                    <input v-model="form.site.head_of_facility"
                                            type="text"
                                            
                                            placeholder="Laboratory Director name"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Laboratory director e-mail</label>
                                    <input v-model="form.site.email_of_head"
                                            type="email"
                                            
                                            placeholder="Laboratory director e-mail"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Date PT received</label>
                                    <input v-model="form.date_pt_recieved"
                                            type="date"
                                            
                                            placeholder="Date PT received"
                                            class="form-control"
                                    />
                                </div>
                                <!--<div class="col-md-4">
                                    <label >Date PT panel tested</label>
                                    <input v-model="form.date_tested"
                                            type="date"
                                            
                                            placeholder="Date PT panel tested"
                                            class="form-control"
                                    />
                                </div>-->
                                <div class="col-md-4">
                                    <label >Date PT result reported</label>
                                    <input v-model="form.date_pt_result_reported"
                                            type="date"
                                            
                                            placeholder="Date PT result reported"
                                            class="form-control"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label >Instrument</label>
                                    <input v-model="form.instrument"
                                           list="list-timezone"
                                            type="text"
                                            autocomplete="on"
                                            placeholder="Instrument"
                                            class="form-control"
                                           id="input-datalist"
                                    />
                                    <datalist id="list-timezone">
                                        <option v-for="item in instruments_options" :key="item">{{item}}</option>
                                    </datalist>
                                </div>
                                <div class="col-md-4">
                                    <label >Cartridge Lot number</label>
                                    <input v-model="form.catridge_lot_number"
                                           list="list-catridges"
                                            type="number"
                                           autocomplete="on"
                                            placeholder="Cartridge Lot number"
                                            class="form-control"
                                    />
                                    <datalist id="list-catridges">
                                        <option v-for="item in catridges_options" :key="item">{{item}}</option>
                                    </datalist>
                                </div>
                                <div class="col-md-4">
                                    <label >Cartridge expiry date</label>
                                    <input v-model="form.catridge_expiry_date"
                                            type="date"
                                            
                                            placeholder="Cartridge expiry date"
                                            class="form-control"
                                    />
                                </div>
                                <!--<div class="col-md-12">
                                    <div class="col-md-4">
                                        <label >Specimen volume</label>
                                        <input v-model="form.specimen_volume"
                                                type="text"
                                                
                                                placeholder="Specimen volume"
                                                class="form-control"
                                        />
                                    </div>
                                </div>-->
                                <div class="col-md-12" style="display:flex">
                                    <div class="col-md-4">
                                        <label >Sample ID</label>
                                        <input v-model="form.sample_id"
                                                type="text"
                                                
                                                placeholder="Sample ID"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Date tested</label>
                                        <input v-model="form.date_tested"
                                                type="date"
                                                
                                                placeholder="Cartridge expiry date"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Time tested</label>
                                        <date-picker
                                        v-model="form.time_tested"
                                                format="hh:mm:ss a"
                                                value-type="format"
                                                type="time"
                                                placeholder="hh:mm:ss a"
                                        ></date-picker>
                                    </div>
                                </div>
                                <div class="col-md-12" style="display:flex">
                                    <div class="col-md-4">
                                        <label >Time rehydrated</label>
                                        <date-picker
                                        v-model="form.time_rehydrated"
                                                format="hh:mm:ss a"
                                                value-type="format"
                                                type="time"
                                                
                                                placeholder="hh:mm:ss a"
                                        ></date-picker>
                                    </div>
                                    <div class="col-md-4">
                                        <label >Results</label>
                                        <multiselect  v-model="results_sample1" label="name" :options="optionResults"  :multiple="false"   >
                                            </multiselect>
                                    </div>
                                </div>
                                <div class="col-md-12" style="display: flex">
                                    <div class="col-md-4">
                                        <label >Sample ID</label>
                                        <input v-model="form.sample_id2"
                                                type="text"
                                                
                                                placeholder="Sample ID"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Date tested</label>
                                        <input v-model="form.date_tested2"
                                                type="date"
                                                
                                                placeholder="Cartridge expiry date"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Time tested</label>
                                        <date-picker
                                        v-model="form.time_tested2"
                                                format="hh:mm:ss a"
                                                value-type="format"
                                                type="time"
                                                placeholder="hh:mm:ss a"
                                        ></date-picker>
                                    </div>
                                </div>
                                <div class="col-md-12" style="display: flex">
                                    <div class="col-md-4">
                                        <label >Time rehydrated</label>
                                        <date-picker
                                        v-model="form.time_rehydrated2"
                                                format="hh:mm:ss a"
                                                value-type="format"
                                                type="time"
                                                
                                                placeholder="hh:mm:ss a"
                                        ></date-picker>
                                    </div>
                                    <div class="col-md-4">
                                        <label >Results</label>
                                        <multiselect  v-model="results_sample2" label="name" :options="optionResults"  :multiple="false"   >
                                            </multiselect>
                                    </div>
                                </div>
                                
                                <div class="col-md-12" style="display:flex">
                                    <div class="col-md-4">
                                        <label >Sample ID</label>
                                        <input v-model="form.sample_id3"
                                                type="text"
                                                
                                                placeholder="Sample ID"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Date tested</label>
                                        <input v-model="form.date_tested3"
                                                type="date"
                                                
                                                placeholder="Cartridge expiry date"
                                                class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <label >Time tested</label>
                                        <date-picker
                                        v-model="form.time_tested3"
                                                format="hh:mm:ss a"
                                                value-type="format"
                                                type="time"
                                                placeholder="hh:mm:ss a"
                                        ></date-picker>
                                    </div>
                                </div>
                                <div class="col-md-12" style="display: flex">
                                    <div class="col-md-4">
                                    <label >Time rehydrated</label>
                                    <date-picker
                                    v-model="form.time_rehydrated3"
                                            format="hh:mm:ss a"
                                            value-type="format"
                                            type="time"
                                            
                                            placeholder="hh:mm:ss a"
                                    ></date-picker>
                                </div>
                                <div class="col-md-4">
                                    <label >Results</label>
                                    <multiselect  v-model="results_sample3" label="name" :options="optionResults"  :multiple="false"   >
                                        </multiselect>
                                </div>
                                </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Operator Name</label>
                                    <input type="text" placeholder="Operator Name" v-model="form.operator_name" class="form-control"/>
                                </div>
                            </div>
                                
                                <!--<div class="col-md-4">
                                    <label >Error Code</label>
                                    <multiselect  v-model="form.error_code" :options="options4"  :multiple="false"   >
                                        </multiselect>
                                </div>-->
                                <div class="col-md-4">
                                    <label >Name and signature of tester</label>
                                    <multiselect  :options="options3" v-model="form.name_and_signature_of_tester" placeholder="Name and signature of tester"></multiselect>
                                    
                                </div>
                                <div class="col-md-4">
                                    <label >Name and signature of reviewer</label>
                                    <multiselect  :options="options3" v-model="form.name_and_signature_of_reviewer" placeholder="Name and signature of reviewer"></multiselect>
                                    <!-- <input v-model="form.name_and_signature_of_reviewer"
                                            type="text"
                                            placeholder="Name and signature of reviewer"
                                            class="form-control"
                                    /> -->
                                </div>
                                <div class="col-md-12">
                                    <label >Comment </label>
                                    <input v-model="form.comment" 
                                            type="text"
                                            placeholder="Comment"
                                            class="form-control"
                                    />
                                </div>
                            <!--<div class="col-md-4 mt-4" style="display: flex; align-items: center">

                            </div>-->
                            <div class="col-md-4 mt-4" style="display: flex; align-items: center">
                                <input type="file" accept="image/jpeg" @change="uploadImage"/>
                                <img v-if="form.filename" :src="baseurl+'/uploads/'+form.filename" width="100"/>
                            </div>
                            <div v-if="form.filename || previewImage" class="col-md-4 mt-4" style="display: flex; align-items: center">
                                <button class="btn btn-danger" @click.prevent="removeImage()">Remove Image</button>
                            </div>

                        </div>
                        <div style="display: flex; justify-content: flex-end">
                            <button class="btn btn-primary mt-3" type="submit" @click.prevent="saveResultForm()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body" v-if="form.site.site_category === 'VL'">
                        <h4 class="card-title">Checklist for PT Panel Verification</h4>
                        <div class="row mx-1">
                            <div class="col-md-3 form-check mb-3 ">
                                <input class="form-check-input" type="checkbox" v-model="second_form.name_of_health" id="check1" />
                                <label class="form-check-label" for="check1">Name of health facility (automatic)</label>
                            </div>
                            <div class="col-md-3 form-check mb-3 ">
                                <input class="form-check-input" type="checkbox" v-model="second_form.name_of_pt_panel" id="check2" />
                                <label class="form-check-label" for="check2">Name of PT panel recipient</label>
                            </div>
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.cryotubes" id="check3" />
                                <label class="form-check-label" for="check3">6 cryotubes</label>
                            </div>
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.reconstituion_solution" id="check4" />
                                <label class="form-check-label" for="check4">Reconstitution solution (buffer)</label>
                            </div>
                        </div>
                        <div class="row mx-1">
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.pasteur_pipette" id="check5" />
                                <label class="form-check-label" for="check5">2 Pasteur pipettes</label>
                            </div>
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.dts_insert" id="check6" />
                                <label class="form-check-label" for="check6">3 DTS inserts (DTS job aid, DTS testing procedure, shipment/Result forms)</label>
                            </div>
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.individual_performance" id="check7" />
                                <label class="form-check-label" for="check7">PT individual performance and Global report</label>
                            </div>
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.signature" id="check8" />
                                <label class="form-check-label" for="check8">Signature (signed?)</label>
                            </div>
                        </div>
                        <div class="row mx-1">
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.name_of_site_supervisor" id="check10" />
                                <label class="form-check-label" for="check10">Name of site supervisor?</label>
                            </div>
                            <div class="col-md-3 form-check mb-3 ">
                                <input class="form-check-input" type="checkbox" v-model="second_form.name_of_site_supervisor_signature" id="check11" />
                                <label class="form-check-label" for="check11">Signature (signed?)</label>
                            </div>
                            <div class="col-md-6 form-check mb-3">
                                <label class="form-check-label" >Comment</label>
                                <textarea
                                        v-model="second_form.comment"
                                        class="form-control"
                                        :maxlength="225"
                                        rows="3"
                                        placeholder="Enter comment here"
                                        style="resize: none"
                                ></textarea>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: flex-end">
                            <button class="btn btn-primary" @click.prevent="secondForm()" type="submit">Save</button>
                        </div>
                    </div>
                    <div class="card-body" v-else>
                        <h4 class="card-title">Checklist for PT Panel Verification</h4>
                        <div class="row mx-1">
                            <div class="col-md-3 form-check mb-3 ">
                                <input class="form-check-input" type="checkbox" v-model="second_form.name_of_health" id="check1" />
                                <label class="form-check-label" for="check1">Instruction form</label>
                            </div>
                            <div class="col-md-3 form-check mb-3 ">
                                <input class="form-check-input" type="checkbox" v-model="second_form.name_of_pt_panel" id="check2" />
                                <label class="form-check-label" for="check2">Six disposable pipettes</label>
                            </div>
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.cryotubes" id="check3" />
                                <label class="form-check-label" for="check3">Three Dried Tube Specimens (DTS)</label>
                            </div>
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.reconstituion_solution" id="check4" />
                                <label class="form-check-label" for="check4">One 5 ml tube of PT buffer (1X Phosphate-Buffered Saline) for DTS rehydration </label>
                            </div>
                        </div>
                        <div class="row mx-1">
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.pasteur_pipette" id="check5" />
                                <label class="form-check-label" for="check5">POC EID DTS Job Aid </label>
                            </div>
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.dts_insert" id="check6" />
                                <label class="form-check-label" for="check6">Results Submission Form</label>
                            </div>
                            <!--<div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.individual_performance" id="check7" />
                                <label class="form-check-label" for="check7">PT individual performance and Global report</label>
                            </div>
                            <div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.signature" id="check8" />
                                <label class="form-check-label" for="check8">Signature (signed?)</label>
                            </div>-->
                        </div>
                        <div class="row mx-1">
                            <!--<div class="col-md-3 form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="second_form.name_of_site_supervisor" id="check10" />
                                <label class="form-check-label" for="check10">Name of site supervisor?</label>
                            </div>
                            <div class="col-md-3 form-check mb-3 ">
                                <input class="form-check-input" type="checkbox" v-model="second_form.name_of_site_supervisor_signature" id="check11" />
                                <label class="form-check-label" for="check11">Signature (signed?)</label>
                            </div>-->
                            <div class="col-md-6 form-check mb-3">
                                <label class="form-check-label" >Comment</label>
                                <textarea
                                        v-model="second_form.comment"
                                        class="form-control"
                                        :maxlength="225"
                                        rows="3"
                                        placeholder="Enter comment here"
                                        style="resize: none"
                                ></textarea>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: flex-end">
                            <button class="btn btn-primary" @click.prevent="secondForm()" type="submit">Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
                            <template v-slot:button-content>
                                <i class="mdi mdi-dots-vertical"></i>
                            </template>
                            <b-dropdown-item>Export Report</b-dropdown-item>
                        </b-dropdown>

                        <h4 class="card-title mb-4">Recent Activity Feed</h4>

                        <div data-simplebar style="max-height: 480px;">
                            <ul class="list-unstyled activity-wid">
                                <li class="activity-list" v-for="(data, index) in activityData" :key="index">
                                    <div class="activity-icon avatar-xs">
                                    <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                                        <i :class="`${data.icon}`"></i>
                                    </span>
                                    </div>
                                    <div>
                                        <div>
                                            <h5 class="font-size-13">
                                                {{data.date}}
                                                <small class="text-muted">{{data.time}}</small>
                                            </h5>
                                        </div>

                                        <div>
                                            <p class="text-muted mb-0">{{data.title}}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lodader__content" v-if="loader">
                <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import DatePicker from "vue2-datepicker";

    import {tableData} from "./dataTracking";
    import {axios_get, axios_put, BASEURL} from "../../../helpers/helper";

    /**
     * Form validation component
     */
    export default {
        page: {
            title: "Tracking Information",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout,PageHeader,DatePicker,Multiselect },
        data() {
            return {
                display:[false,false],
                displayForm:[false,false],
                displayReason:[false],
                year:null,
                ptData:null,
                date_of_drop:null,
                date_of_pickup:null,
                form:{},
                loader: false,
                enrollment:{},
                instruments_options: [],
                catridges_options: [],
                options:[
                    'Pick up from provider',
                    'Drop at site',
                    'Starting testing',
                    'Ended Testing',
                    'Pick up from site',
                    'Dropped at provider',

                ],
                options1:[
                    'Biker 1',
                    'Biker 2',
                    'Biker 3',
                ],
                options2:[
                    'EID',
                    'VL'
                ],
                options3:[
                    'Yes',
                    'No'
                ],
                options4:[
                    'Yes',
                    'No'
                ],
                results_sample1:"",
                results_sample2:"",
                results_sample3:"",
                optionResults:[
                    {
                        "value": 0,
                        "name":"Undetected"
                    },
                    {
                        "value": 1,
                        "name": "Detected",
                    },
                    {
                        "value": 2,
                        "name": "Invalid",
                    },
                    {
                        "value": 3,
                        "name": "",
                    }
                ],
                bikerData:[],
                biker_name:null,
                bikerOption:[],
                second_form:{},
                value:null,
                baseurl:BASEURL,
                defaultdate1:null,
                defaultdate:null,
                dueDate:null,
                timeDrop:null,
                timePick:null,
                timeRe:null,
                timeRe2:null,
                timeTested2:null,
                timeTested:null,
                value1:null,
                value2:null,
                value3:null,
                value4:null,
                value5:null,
                previewImage:null,
                activityData: [],
                title: "Tracking Information",
                items: [
                    {
                        text: "PT Round",
                        href: "/pt-round/"+this.$route.params.id
                    },
                    {
                        text: "Tracking Information",
                        active: true
                    }
                ],
                series: [42, 26, 15],
                chartOptions: {
                    chart: {
                        height: 230,
                        type: "donut"
                    },
                    labels: ["Product A", "Product B", "Product C"],
                    plotOptions: {
                        pie: {
                            donut: {
                                size: "75%"
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: false
                    },
                    colors: ["#5664d2", "#1cbb8c", "#eeb902"]
                },
                tableData: tableData,
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: false,
                fields: [
                    { key: "operation", sortable: false },
                    { key: "date", sortable: false },
                    { key: "biker", sortable: false },
                    { key: "coverageArea", sortable: false },
                    { key: "actions", sortable: false },
                ],
            }
        },
        mounted() {
            this.user = JSON.parse(localStorage.getItem('user'))

            axios_get("/possibilities", {}, (d)=>{
                this.instruments_options = d.instruments.filter((i, e)=>d.instruments.indexOf(i)===e)
                this.catridges_options = d.catridges.filter((i, e)=>d.catridges.indexOf(i)===e)
            }, (e)=>{
                console.log(e)
            }, ()=>{
                console.log("finally")
            })

            axios_get("/bikers",{},(da)=>{
                this.bikerOption=da.filter(e=>{return e.country_id == this.user.country_id})
                var rout = window.location.pathname.split('/')
                axios_get("/enrollment/"+rout[rout.length-1],{},(d)=>{
                    this.form=d
                    console.log(this.form)
                    if(d.checklist){
                        this.second_form = JSON.parse(d.checklist)
                    }
                    if(d.site.site_category==='EID'){
                        this.results_sample1 = this.optionResults.filter(e=>{return e.value===d.results_sample1})[0]
                        this.results_sample2 = this.optionResults.filter(e=>{return e.value===d.results_sample2})[0]
                        this.results_sample3 = this.optionResults.filter(e=>{return e.value===d.results_sample3})[0]
                    }
                    this.biker_name = da.filter(e=>{return e.id===parseInt(d.biker_id)})
                    console.log(this.optionResults)
                    console.log(this.results_sample1)
                    console.log(this.results_sample2)
                    console.log(this.results_sample3)
                    this.year = new Date(d.year+'-'+d.month)
                    if(d.date_of_drop){
                        var date_of_drop = d.date_of_drop.split('/')
                        this.date_of_drop = new Date(date_of_drop[2]+'-'+date_of_drop[1]+'-'+date_of_drop[0])
                    }
                    if(d.date_of_pickup){
                        var date_of_pickup = d.date_of_pickup.split('/')
                        this.date_of_pickup = new Date(date_of_pickup[2]+'-'+date_of_pickup[1]+'-'+date_of_pickup[0])
                    }

                    axios_get("/pt_rounds/"+this.form.pt_round_id,{},(da)=>{
                        this.ptData=da.ptround.month+"/"+da.ptround.year;
                        console.log(this.ptData);
                        },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                        }
                    );

                    this.activityData = [{
                        icon: "ri-edit-2-fill",
                        date: d.date_of_pickup,
                        time: d.time_of_pickup,
                        title: "Pick up from provider"
                    },
                    {
                        icon: "ri-user-2-fill",
                        date: d.date_of_drop,
                        time: d.time_of_drop,
                        title: "Dropped at site”"
                    },
                    {
                        icon: "ri-bar-chart-fill",
                        date: d.date_of_drop,
                        time: d.time_of_drop,
                        title: "Start testing”"
                    },
                    {
                        icon: "ri-calendar-2-fill",
                        date: d.date_tested,
                        time: d.time_tested,
                        title: "Ended testing”"
                    },
                    {
                        icon: "ri-edit-2-fill",
                        date: d.date_tested,
                        time: d.time_tested,
                        title: "Pick up from site"
                    },
                    {
                        icon: "ri-user-2-fill",
                        date: d.date_of_drop,
                        time: d.time_of_drop,
                        title: "Dropped at provider"
                    }]
                    
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                    }
                );
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
        
        },
        methods:{
            removeImage(){
                this.form.filename = null
                this.previewImage = null
                console.log(this.form)
            },
            selection(e){
                if (e){
                    console.log(this.form)
                    console.log(e)
                    this.form.coverage_area = e.coverage_area
                    console.log(this.form.coverage_area)
                }else{
                    this.form.coverage_area = null
                }
            },
            selected(value){
                switch (value) {
                    case 'Pick up from provider':
                        this.display=[true,false];
                        break;
                    case 'Drop at site':
                        this.display=[true,false];
                        break;
                    case 'Ended Testing':
                        this.display=[false,true];
                        break;
                    case 'Pick up from site':
                        this.display=[true,false];
                        break;
                    case 'Dropped at provider':
                        this.display=[true,false];
                        break;
                    default:
                        this.display=[false,false]
                }
            },
            oneditBicker(){
                this.loader = true
                console.log(this.form.date_of_pickup)
                console.log(this.biker_name)
                console.log(this.form.biker_id)
                this.form.biker_id = this.biker_name.id
                /*this.form.year = this.year.getFullYear()
                this.form.month = this.year.getMonth()+1*/
                var date_of_pickup = new Date(this.date_of_pickup).toLocaleDateString()
                this.form.date_of_pickup = date_of_pickup.split('T')[0];
                var date_of_drop = new Date(this.date_of_drop).toLocaleDateString()
                this.form.date_of_drop = date_of_drop.split('T')[0];
                console.log(this.form)
                var rout = window.location.pathname.split('/')
                axios_put('/enrollment/'+rout[rout.length-1], this.form, (d)=>{
                    console.log('success', d)
                }, (err)=>{
                    console.log('error', err)
                }, (f)=>{
                    console.log('final', f)
                    this.loader = false
                })
            },
            saveResultForm(){
                this.loader = true
                if(this.form.site.site_category === 'EID'){
                    if(this.results_sample1){
                        this.form.results_sample1 = this.results_sample1.value
                    }
                    if(this.results_sample2){
                        this.form.results_sample2 = this.results_sample2.value
                    }
                    if(this.results_sample3){
                        this.form.results_sample3 = this.results_sample3.value
                    }
                }
                console.log(this.form)
                var formdata = new FormData()
                if(this.previewImage){
                    formdata.append('file', this.previewImage)
                }
                Object.keys(this.form).map(e=>{
                    if(this.form[e] || this.form[e]==0){
                        formdata.append(e, this.form[e])
                    }
                })
                this.previewImage = null
                console.log(this.previewImage)
                console.log(this.form)
                console.log(formdata)
                var rout = window.location.pathname.split('/')
                axios_put('/enrollment/'+rout[rout.length-1], formdata, (d)=>{
                    console.log('success', d)
                }, (err)=>{
                    console.log('error', err)
                }, (f)=>{
                    this.loader = false
                    console.log('final', f)
                })
            },
            selectSite(value2){
                switch (value2) {
                    case 'EID':
                        this.displayForm=[true,false];
                        break;
                    case 'VL':
                        this.displayForm=[false,true];
                        break
                    default:
                        this.displayForm=[false,false];
                }

            },
            secondForm(){
                this.loader = true
                console.log(this.form)
                this.form.checklist = JSON.stringify(this.second_form)
                var rout = window.location.pathname.split('/')
                axios_put('/enrollment/'+rout[rout.length-1], this.form, (d)=>{
                    window.location.href = '/pt-round/'+this.form.pt_round_id
                    console.log('success', d)
                }, (err)=>{
                    console.log('error', err)
                }, (f)=>{
                    console.log('final', f)
                    this.loader = false
                })
            },
            selectReason(value4){
                switch (value4) {
                    case 'No':
                        this.displayReason=[true];
                        break;
                    default:
                        this.displayReason=[false];

                }
            },
            uploadImage(e){
                this.previewImage = e.target.files[0];
                console.log(this.previewImage)
            }
        }
    }
</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>