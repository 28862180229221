const tableData = [
  {
    operation: 'Pick up from provider',
    date: '11:00 24-05-2022',
    biker: 'John Doe',
    coverageArea: 'Yaounde-Douala',
  },{
    operation: 'Drop at site',
    date: '11:00 24-05-2022',
    biker: 'John Doe',
    coverageArea: 'Yaounde-Douala',
  },{
    operation: 'Starting testing ',
    date: '11:00 24-05-2022',
    biker: '/',
    coverageArea: 'Yaounde-Douala',
  },
  {
    operation: 'Ended Testing',
    date: '11:00 24-05-2022',
    biker: '/',
    coverageArea: 'Yaounde-Douala',
  },{
    operation: 'Pick up from site ',
    date: '11:00 24-05-2022',
    biker: 'John Doe',
    coverageArea: 'Yaounde-Douala',
  },{
    operation: 'Dropped at provider',
    date: '11:00 24-05-2022',
    biker: 'John Doe',
    coverageArea: 'Yaounde-Douala',
  },

];

export { tableData };
